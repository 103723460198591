<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备" prop="deviceId" >
        <a-select
          showSearch
          optionFilterProp="label"
          style="width: 100%"
          @change="selectDevice"
          @search="getDevice"
          :filter-option="filterOption"
          v-model="form.deviceId">
          <a-select-option :value="item.id" v-for="item in deviceList">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="操作内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="操作前照片" prop="befortImgs" >
        <file-upload type="image" :count="1" :defaultList="form.befortImgsArr" @input="getImg($event, 'befortImgs')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="操作后照片" prop="afterImgs" >
        <file-upload type="image" :count="1" :defaultList="form.afterImgsArr" @input="getImg($event, 'afterImgs')"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="操作员姓名" prop="operatorName" >
        <a-input v-model="form.operatorName" placeholder="请输入操作员姓名" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecord, addRecord, updateRecord } from '@/api/operation/record'
import Editor from '@/components/Editor'
import { pageDevice } from '@/api/device/device'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      // 设备列表
      deviceList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        deviceId: null,

        title: null,

        content: null,

        befortImgs: null,

        befortImgsArr: [],

        afterImgs: null,

        afterImgsArr: [],

        operatorName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [
          { required: true, message: '设备id,外键{zb_device.id}不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '操作内容不能为空', trigger: 'blur' }
        ],
        befortImgs: [
          { required: true, message: '操作前照片,多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        afterImgs: [
          { required: true, message: '操作后照片,多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        operatorName: [
          { required: true, message: '操作员姓名不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getDevice()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    filterOption(value, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(value.toLowerCase()) >= 0
    },
    // 获取设备列表
    getDevice(item) {
      pageDevice({name: item}).then(res => {
        this.deviceList = res.data.records
      })
    },
    // 选择设备
    selectDevice(item) {
      this.form.deviceId = item
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        deviceId: null,
        title: null,
        content: null,
        befortImgs: null,
        befortImgsArr: [],
        afterImgs: null,
        afterImgsArr: [],
        operatorName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.befortImgsArr = []
        this.form.afterImgsArr = []
        let that = this
        if (this.form.befortImgs) {
          this.form.befortImgs.split(",").map(item => {
            this.form.befortImgsArr.push({
              uid: guid(8, 10),
              name: item,
              status: 'done',
              halfUrl: item,
              url: item,
              path: item
            })
          })
        }
        if (this.form.afterImgs) {
          this.form.afterImgs.split(",").map(item => {
            this.form.afterImgsArr.push({
              uid: guid(8, 10),
              name: item,
              status: 'done',
              halfUrl: item,
              url: item,
              path: item
            })
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
