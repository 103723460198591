import request from '@/utils/request'


// 查询操作记录列表
export function listRecord(query) {
  return request({
    url: '/operation/operation-record/list',
    method: 'get',
    params: query
  })
}

// 查询操作记录分页
export function pageRecord(query) {
  return request({
    url: '/operation/operation-record/page',
    method: 'get',
    params: query
  })
}

// 查询操作记录详细
export function getRecord(data) {
  return request({
    url: '/operation/operation-record/detail',
    method: 'get',
    params: data
  })
}

// 新增操作记录
export function addRecord(data) {
  return request({
    url: '/operation/operation-record/add',
    method: 'post',
    data: data
  })
}

// 修改操作记录
export function updateRecord(data) {
  return request({
    url: '/operation/operation-record/edit',
    method: 'post',
    data: data
  })
}

// 删除操作记录
export function delRecord(data) {
  return request({
    url: '/operation/operation-record/delete',
    method: 'post',
    data: data
  })
}
